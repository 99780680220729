import React, { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { Label } from "./ui/label";

const AddTrackForm = ({ onAddTrack, onCancel, artist }) => {
  const [newTrack, setNewTrack] = useState({
    publishingDetails: {
      roles: [{ role: '', songwriter: '', percentage: '', publisher: '' }]
    },
    songDetails: {
      title: '',
      artist: artist,
      language: '',
      duration: '',
      releaseDate: ''
    },
    masterRecordingDetails: {
      version: '',
      isrc: '',
      upc: '',
      repertoireOwner: ''
    },
    link: ''
  });

  useEffect(() => {
    // Load temporary tracks from local storage on mount
    const existingTracks = JSON.parse(localStorage.getItem('temporaryTracks')) || [];
    setNewTrack(existingTracks[existingTracks.length - 1] || newTrack);
  }, []);

  const handleNewTrackChange = (field, value) => {
    setNewTrack(prev => {
      const updated = { ...prev };
      const fields = field.split('.');
      let current = updated;
      for (let i = 0; i < fields.length - 1; i++) {
        if (fields[i] === 'roles' && !isNaN(fields[i + 1])) {
          const index = parseInt(fields[i + 1]);
          if (!current.roles[index]) {
            current.roles[index] = {};
          }
          current = current.roles[index];
          i++;
        } else {
          if (!current[fields[i]]) {
            current[fields[i]] = {};
          }
          current = current[fields[i]];
        }
      }
      current[fields[fields.length - 1]] = value;
      return updated;
    });
  };

  const addRole = () => {
    setNewTrack(prev => ({
      ...prev,
      publishingDetails: {
        ...prev.publishingDetails,
        roles: [...prev.publishingDetails.roles, { role: '', songwriter: '', percentage: '', publisher: '' }]
      }
    }));
  };

  const removeRole = (index) => {
    setNewTrack(prev => ({
      ...prev,
      publishingDetails: {
        ...prev.publishingDetails,
        roles: prev.publishingDetails.roles.filter((_, i) => i !== index)
      }
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const existingTracks = JSON.parse(localStorage.getItem('temporaryTracks')) || [];
    existingTracks.push(newTrack);
    localStorage.setItem('temporaryTracks', JSON.stringify(existingTracks));
    onAddTrack(newTrack); // Call the parent function to update the catalog later
  };

  return (
    <Card className="bg-card text-card-foreground mt-4">
      <CardHeader>
        <CardTitle>Add New Track for {artist}</CardTitle>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Song Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="title">Title</Label>
                <Input
                  id="title"
                  value={newTrack.songDetails.title}
                  onChange={(e) => handleNewTrackChange('songDetails.title', e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="artist">Artist</Label>
                <Input
                  id="artist"
                  value={newTrack.songDetails.artist}
                  disabled
                />
              </div>
              <div>
                <Label htmlFor="language">Language</Label>
                <Input
                  id="language"
                  value={newTrack.songDetails.language}
                  onChange={(e) => handleNewTrackChange('songDetails.language', e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="duration">Duration</Label>
                <Input
                  id="duration"
                  value={newTrack.songDetails.duration}
                  onChange={(e) => handleNewTrackChange('songDetails.duration', e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="releaseDate">Release Date</Label>
                <Input
                  id="releaseDate"
                  type="date"
                  value={newTrack.songDetails.releaseDate}
                  onChange={(e) => handleNewTrackChange('songDetails.releaseDate', e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Publishing Details</h3>
            {newTrack.publishingDetails.roles.map((role, index) => (
              <div key={index} className="space-y-2 p-2 border rounded">
                <h4>Role {index + 1}</h4>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <Label htmlFor={`role-${index}`}>Role</Label>
                    <Input
                      id={`role-${index}`}
                      value={role.role}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.role`, e.target.value)}
                    />
                  </div>
                  <div>
                    <Label htmlFor={`songwriter-${index}`}>Songwriter</Label>
                    <Input
                      id={`songwriter-${index}`}
                      value={role.songwriter}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.songwriter`, e.target.value)}
                    />
                  </div>
                  <div>
                    <Label htmlFor={`percentage-${index}`}>Percentage</Label>
                    <Input
                      id={`percentage-${index}`}
                      value={role.percentage}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.percentage`, e.target.value)}
                    />
                  </div>
                  <div>
                    <Label htmlFor={`publisher-${index}`}>Publisher</Label>
                    <Input
                      id={`publisher-${index}`}
                      value={role.publisher}
                      onChange={(e) => handleNewTrackChange(`publishingDetails.roles.${index}.publisher`, e.target.value)}
                    />
                  </div>
                </div>
                {index > 0 && (
                  <Button type="button" variant="destructive" onClick={() => removeRole(index)}>Remove Role</Button>
                )}
              </div>
            ))}
            <Button type="button" onClick={addRole}>Add Role</Button>
          </div>
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Master Recording Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Label htmlFor="version">Version</Label>
                <Input
                  id="version"
                  value={newTrack.masterRecordingDetails.version}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.version', e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="isrc">ISRC</Label>
                <Input
                  id="isrc"
                  value={newTrack.masterRecordingDetails.isrc}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.isrc', e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="upc">UPC</Label>
                <Input
                  id="upc"
                  value={newTrack.masterRecordingDetails.upc}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.upc', e.target.value)}
                />
              </div>
              <div>
                <Label htmlFor="repertoireOwner">Repertoire Owner</Label>
                <Input
                  id="repertoireOwner"
                  value={newTrack.masterRecordingDetails.repertoireOwner}
                  onChange={(e) => handleNewTrackChange('masterRecordingDetails.repertoireOwner', e.target.value)}
                />
              </div>
            </div>
          </div>
          <div>
            <Label htmlFor="link">YouTube Link</Label>
            <Input
              id="link"
              value={newTrack.link}
              onChange={(e) => handleNewTrackChange('link', e.target.value)}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button type="submit">Add Track</Button>
            <Button type="button" variant="outline" onClick={onCancel}>Cancel</Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default AddTrackForm;