import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/ui/card";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../components/ui/tabs";
import { ScrollArea } from "../components/ui/scroll-area";
import { Badge } from "../components/ui/badge";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion";
import { Music, Mic, Calendar, Globe } from 'lucide-react';

const SummaryTab = ({ catalog }) => {
  const getSummary = () => {
    const summary = {
      totalWorks: 0,
      recentReleases: [],
      upcomingReleases: [],
      artistStats: {},
      monthlyReleases: Array(12).fill(0),
      totalPublishers: new Set(),
      totalSongwriters: new Set(),
      languageDistribution: {}
    };

    const now = new Date();
    const thirtyDaysAgo = new Date(now - 30 * 24 * 60 * 60 * 1000);

    Object.entries(catalog).forEach(([artist, songs]) => {
      summary.totalWorks += songs.length;
      summary.artistStats[artist] = { 
        totalSongs: songs.length, 
        recentReleases: 0, 
        upcomingReleases: 0,
        publishers: new Set(),
        songwriters: new Set(),
        languages: {}
      };

      songs.forEach(song => {
        const releaseDate = new Date(song.songDetails.releaseDate);
        if (!isNaN(releaseDate.getTime())) {
          if (releaseDate <= now && releaseDate >= thirtyDaysAgo) {
            summary.recentReleases.push(song);
            summary.artistStats[artist].recentReleases++;
          } else if (releaseDate > now) {
            summary.upcomingReleases.push(song);
            summary.artistStats[artist].upcomingReleases++;
          }

          if (releaseDate.getFullYear() === now.getFullYear()) {
            summary.monthlyReleases[releaseDate.getMonth()]++;
          }
        }

        // Count publishers and songwriters
        song.publishingDetails.roles.forEach(role => {
          summary.totalPublishers.add(role.publisher);
          summary.totalSongwriters.add(role.songwriter);
          summary.artistStats[artist].publishers.add(role.publisher);
          summary.artistStats[artist].songwriters.add(role.songwriter);
        });

        // Language distribution
        const language = song.songDetails.language;
        summary.languageDistribution[language] = (summary.languageDistribution[language] || 0) + 1;
        summary.artistStats[artist].languages[language] = (summary.artistStats[artist].languages[language] || 0) + 1;
      });

      // Convert Sets to array lengths
      summary.artistStats[artist].publishers = summary.artistStats[artist].publishers.size;
      summary.artistStats[artist].songwriters = summary.artistStats[artist].songwriters.size;
    });

    summary.recentReleases.sort((a, b) => new Date(b.songDetails.releaseDate) - new Date(a.songDetails.releaseDate));
    summary.upcomingReleases.sort((a, b) => new Date(a.songDetails.releaseDate) - new Date(b.songDetails.releaseDate));

    summary.totalPublishers = summary.totalPublishers.size;
    summary.totalSongwriters = summary.totalSongwriters.size;
    summary.totalArtists = Object.keys(catalog).length;

    return summary;
  };

  const summary = getSummary();
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const chartData = summary.monthlyReleases.map((count, index) => ({
    month: monthNames[index],
    releases: count
  }));

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Card className="bg-card">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium text-card-foreground">Total Works</CardTitle>
            <Music className="h-4 w-4 text-primary" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-card-foreground">{summary.totalWorks}</div>
          </CardContent>
        </Card>
        <Card className="bg-card">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium text-card-foreground">Recent Releases</CardTitle>
            <Calendar className="h-4 w-4 text-primary" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-card-foreground">{summary.recentReleases.length}</div>
          </CardContent>
        </Card>
        <Card className="bg-card">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium text-card-foreground">Total Publishers</CardTitle>
            <Mic className="h-4 w-4 text-primary" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-card-foreground">{summary.totalPublishers}</div>
          </CardContent>
        </Card>
        <Card className="bg-card">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium text-card-foreground">Total Songwriters</CardTitle>
            <Mic className="h-4 w-4 text-primary" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-card-foreground">{summary.totalArtists}</div>
          </CardContent>
        </Card>
      </div>
      <Card className="bg-card">
        <CardHeader>
          <CardTitle className="text-card-foreground">Monthly Releases (This Year)</CardTitle>
        </CardHeader>
        <CardContent>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={chartData}>
              <XAxis 
                dataKey="month" 
                stroke="hsl(var(--muted-foreground))"
                fontSize={12}
              />
              <YAxis 
                stroke="hsl(var(--muted-foreground))"
                fontSize={12}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: "hsl(var(--card))",
                  border: "1px solid hsl(var(--border))",
                  borderRadius: "var(--radius)",
                  color: "hsl(var(--card-foreground))"
                }}
              />
              <Bar 
                dataKey="releases" 
                fill="hsl(var(--primary))"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card className="bg-card">
          <CardHeader>
            <CardTitle className="text-card-foreground">Artist Statistics</CardTitle>
          </CardHeader>
          <CardContent>
            <ScrollArea className="h-[300px] pr-4">
              <Accordion type="single" collapsible className="w-full">
                {Object.entries(summary.artistStats).map(([artist, stats], index) => (
                  <AccordionItem value={`item-${index}`} key={index}>
                    <AccordionTrigger className="text-card-foreground hover:text-card-foreground">
                      {artist}
                    </AccordionTrigger>
                    <AccordionContent className="text-card-foreground">
                      <div className="space-y-2">
                        <p><strong>Total Songs:</strong> {stats.totalSongs}</p>
                        <p><strong>Recent Releases:</strong> {stats.recentReleases}</p>
                        <p><strong>Upcoming Releases:</strong> {stats.upcomingReleases}</p>
                        <p><strong>Publishers:</strong> {stats.publishers}</p>
                        <p><strong>Songwriters:</strong> {stats.songwriters}</p>
                        <div><strong>Languages:</strong> 
                          {Object.entries(stats.languages).map(([lang, count]) => (
                            <Badge 
                              key={lang} 
                              variant="secondary" 
                              className="ml-2 bg-secondary text-secondary-foreground"
                            >
                              {lang} ({count})
                            </Badge>
                          ))}
                        </div>
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </ScrollArea>
          </CardContent>
        </Card>

        <Card className="bg-card">
          <CardHeader>
            <CardTitle className="text-card-foreground">Recent and Upcoming Releases</CardTitle>
          </CardHeader>
          <CardContent>
            <Tabs defaultValue="recent">
              <TabsList className="grid w-full grid-cols-2 bg-muted">
                <TabsTrigger 
                  value="recent"
                  className="data-[state=active]:bg-background data-[state=active]:text-foreground"
                >
                  Recent
                </TabsTrigger>
                <TabsTrigger 
                  value="upcoming"
                  className="data-[state=active]:bg-background data-[state=active]:text-foreground"
                >
                  Upcoming
                </TabsTrigger>
              </TabsList>
              <TabsContent value="recent">
                <ScrollArea className="h-[200px] mt-4 pr-4">
                  {summary.recentReleases.slice(0, 10).map((song, index) => (
                    <div key={index} className="flex items-center justify-between py-2">
                      <span className="text-card-foreground">{song.songDetails.title}</span>
                      <Badge variant="outline" className="border-border text-card-foreground">
                        {song.songDetails.releaseDate}
                      </Badge>
                    </div>
                  ))}
                </ScrollArea>
              </TabsContent>
              <TabsContent value="upcoming">
                <ScrollArea className="h-[200px] mt-4 pr-4">
                  {summary.upcomingReleases.slice(0, 10).map((song, index) => (
                    <div key={index} className="flex items-center justify-between py-2">
                      <span className="text-card-foreground">{song.songDetails.title}</span>
                      <Badge variant="outline" className="border-border text-card-foreground">
                        {song.songDetails.releaseDate}
                      </Badge>
                    </div>
                  ))}
                </ScrollArea>
              </TabsContent>
            </Tabs>
          </CardContent>
        </Card>
      </div>

      <Card className="bg-card">
        <CardHeader>
          <CardTitle className="text-card-foreground">Language Distribution</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Object.entries(summary.languageDistribution).map(([language, count], index) => (
              <div key={index} className="flex items-center space-x-2">
                <Globe className="h-4 w-4 text-primary" />
                <span className="text-card-foreground">{language}:</span>
                <Badge variant="secondary" className="bg-secondary text-secondary-foreground">
                  {count}
                </Badge>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SummaryTab;